import React from "react";

import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import ReactDatetime from "react-datetime";

import * as couponsActions from "stores/orders/coupons/actions";

import Dropzone from "dropzone";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import NumericInput from "components/Form/NumericInput";
import CountrySelector from "../orders/cards/CountrySelector";

Dropzone.autoDiscover = false;

class Coupon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      coupon: null,
      auth: null,
      action: "",
      session: null,
      methods: [],
      currentCulture: this.props.session.culture,
      provisionSelectedCountry: "",
      availabilitySelectedCountry: "",
    };
    this.myRef = React.createRef();
  }

  componentDidMount = () => {
    const id = this.props.match.params.id;
    if (id !== "create") {
      this.props.read(id);
    } else {
      this.setState({
        coupon: {
          id: undefined,
          title: "",
          line1: "",
          line2: "",
          city: "",
          countryCode: "",
          postalCode: "",
          state: "",
          phone: "",
          email: "",
        },
        loading: false,
      });
    }
  };

  changeCulture = (event) => {
    let value = event.target.value;
    let currentCulture = value;
    this.setState({ currentCulture });
  };

  parseValue = (target) => {
    const { name, value, checked, type } = target;
    let parsedValue = value;
    switch (type) {
      case "number":
        parsedValue = Number(parsedValue);
        break;
      case "checkbox":
        parsedValue = checked;
        break;
      case "datetime":
        parsedValue = value.format();
        break;
      default:
        break;
    }
    return { name: name, value: parsedValue };
  };

  changeCouponDate = (name, value) => {
    const valueSet = this.parseValue({
      name: name,
      value: value,
      checked: false,
      type: "datetime",
    });
    let coupon = { ...this.state.coupon, [valueSet.name]: valueSet.value };
    this.setState({ coupon });
  };

  changeCouponData = (event) => {
    const value = this.parseValue(event.target);
    let coupon = { ...this.state.coupon };
    coupon = { ...coupon, [value.name]: value.value };
    this.setState({ coupon });
  };

  generateCode = () => {
    let coupon = { ...this.state.coupon };
    let code = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    for (let i = 0; i < 10; i++) {
      code += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    coupon.code = code;
    this.setState({ coupon });
  }

  submitForm = (event) => {
    var coupon = { ...this.state.coupon };
    if (coupon.id) {
      this.setState({ action: "update" });
      this.props.update(coupon);
    } else {
      this.setState({ action: "create" });
      this.props.create(coupon);
    }
  };

  loadingOverlay = () => {
    return (
      <div
        className="position-absolute h-100 w-100"
        style={{
          backgroundColor: "rgba(255,255,255,0.8)",
          zIndex: 999,
          verticalAlign: "middle",
        }}
      >
        <div className="d-table h-100 w-100">
          <div className="text-center d-table-cell h-100 w-100 align-middle">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  onClickDelete = () => {
    this.props.remove(this.state.coupon.id);
    this.setState({ action: "remove" });
  };

  renderDeleteButton = () => {
    const loading = !this.state.coupon || this.props.loading;

    const { coupon } = this.state;
    const { auth } = this.props;

    if (!coupon || coupon.id === auth.id || !coupon.id) {
      return <></>;
    }

    return (
      <Button color="danger" onClick={this.onClickDelete} disabled={loading}>
        <i className="fas fa-times" />
      </Button>
    );
  };

  renderButtons = () => {
    const loading = !this.state.coupon || this.props.loading;

    return (
      <div className="text-right">
        <Button color="primary" onClick={this.submitForm} disabled={loading}>
          Zapisz
        </Button>
        {this.renderDeleteButton()}
      </div>
    );
  };

  renderId = () => {
    const { coupon } = this.state;

    if (!coupon || !coupon.id) {
      return <></>;
    }

    return (
      <React.Fragment>
        <Col md="3">Id</Col>
        <Col md="9">
          <FormGroup>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-tag" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Id"
                type="text"
                name="id"
                value={coupon ? coupon.id : ""}
                readOnly={true}
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </React.Fragment>
    );
  };

  renderCardRestrictions = () => {
    const { coupon } = this.state;

    if (!coupon) {
      return <></>;
    }

    const loading = this.props.loading;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Ograniczenia</h3>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              <Col md="3">Czas trwania</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <ReactDatetime
                      inputProps={{
                        placeholder: "Początek",
                        className: "form-control",
                      }}
                      value={new Date(coupon.start)}
                      timeFormat={false}
                      dateFormat="yyyy-MM-DD"
                      onChange={this.changeCouponDate.bind(this, "start")}
                      name={"start"}
                      className="form-control p-0 m-0 border-0"
                    />
                    <ReactDatetime
                      inputProps={{
                        placeholder: "Koniec",
                        className: "form-control",
                      }}
                      value={new Date(coupon.end)}
                      timeFormat={false}
                      dateFormat="yyyy-MM-DD"
                      onChange={this.changeCouponDate.bind(this, "end")}
                      name={"end"}
                      className="form-control p-0 m-0 border-0"
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Zakres cenowy</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <NumericInput
                      placeholder="Od"
                      name="minimalPrice"
                      value={coupon.minimalPrice}
                      onChange={this.changeCouponData}
                      step={0.01}
                    />
                    <NumericInput
                      placeholder="Do"
                      name="maximalPrice"
                      value={coupon.maximalPrice}
                      onChange={this.changeCouponData}
                      step={0.01}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>zł</InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Limit uzyć</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <NumericInput
                      placeholder="Limit uzyć"
                      name="limit"
                      value={coupon.limit}
                      onChange={this.changeCouponData}
                      step={1}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Kraj</Col>
              <Col md="9">
                <FormGroup>
                  <CountrySelector
                    value={coupon.countryCode}
                    name="countryCode"
                    onChange={this.changeCouponData}
                    allowNull
                  />
                </FormGroup>
              </Col>
              <Col md="9">Uzytkownik moze skorzystać tylko raz?</Col>
              <Col md="3" className="text-right">
                <label className="custom-toggle">
                  <Input
                    type="checkbox"
                    name="isOncePerUser"
                    onChange={(e) => this.changeCouponData(e)}
                    checked={coupon.isOncePerUser}
                    defaultChecked={coupon.isOncePerUser}
                  />
                  <span className="custom-toggle-slider rounded-circle" />
                </label>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  renderCardData = () => {
    const { coupon } = this.state;

    if (!coupon) {
      return <></>;
    }

    const loading = this.props.loading;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Podstawowe dane</h3>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              {this.renderId()}
              <Col md="3">Kod</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-barcode" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Kod"
                      type="text"
                      name="code"
                      value={coupon.code}
                      onChange={this.changeCouponData}
                    />
                    <InputGroupAddon addonType="append">
                      <Button type="button" color="primary" onClick={this.generateCode}>
                        <i className="fas fa-cog" />
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Obnizka procentowa</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-percent" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <NumericInput
                      placeholder="Procent"
                      name="rate"
                      value={coupon.rate}
                      onChange={this.changeCouponData}
                      step={0.01}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Obnizka stała</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-coins" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <NumericInput
                      placeholder="Stała"
                      name="const"
                      value={coupon.const}
                      onChange={this.changeCouponData}
                      step={0.01}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  render = () => {
    const loading = !this.state.coupon || this.props.loading;

    const { coupon } = this.state;
    const { action } = this.state;

    if ((action === "create" || action === "remove") && loading === false) {
      return <Redirect to="/admin/orders/coupons/" />;
    }

    return (
      <>
        <SimpleHeader
          name={coupon ? coupon.currentTranslation?.title : ""}
          link="/admin/orders/coupons"
          parentName="Kody rabatowe"
        >
          {this.renderButtons()}
        </SimpleHeader>
        <Container className="mt--6" fluid>
          {loading ? this.loadingOverlay() : ""}
          <Row>
            <Col lg="6">
              <div className="card-wrapper">{this.renderCardData()}</div>
            </Col>
            <Col lg="6">
              <div className="card-wrapper">
                {this.renderCardRestrictions()}
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  componentDidUpdate(prevProps) {
    if (prevProps.coupon !== this.props.coupon) {
      this.setState({
        coupon: this.props.coupon,
        loading: this.props.loading,
        auth: this.props.auth,
      });
    }
  }
}

function mapStateToProps(state) {
  return {
    coupon: state.coupons.current,
    loading: state.coupons.loading,
    auth: state.auth.user,
    session: state.session,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    read: (id) => dispatch(couponsActions.read(id)),
    remove: (id) => dispatch(couponsActions.remove(id)),
    update: (coupon) => dispatch(couponsActions.update(coupon)),
    create: (coupon) => dispatch(couponsActions.create(coupon)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Coupon));
